import { Component } from '@angular/core';
import { HeroComponent } from '../../core/components/hero/hero.component';
import { CallToActionComponent } from '../../core/components/call-to-action/call-to-action.component';
import { TestimonialsComponent } from '../../core/components/testimonials/testimonials.component';
import { PricingComponent } from '../../core/components/pricing/pricing.component';
import { FaqsComponent } from '../../core/components/faqs/faqs.component';

@Component({
  selector: 'cs-home',
  imports: [
    HeroComponent,
    CallToActionComponent,
    TestimonialsComponent,
    PricingComponent,
    FaqsComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {}
