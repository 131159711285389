import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild, WritableSignal, effect } from '@angular/core';
import { FlagComponent } from '../flag/flag.component';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroChevronDownMini } from '@ng-icons/heroicons/mini';
import countries from '../../../shared/countries';

@Component({
  selector: 'cs-currency-select',
  imports: [CommonModule, FlagComponent, NgIconComponent],
  providers: [provideIcons({ heroChevronDownMini })],
  templateUrl: './currency-select.component.html',
  styleUrl: './currency-select.component.scss'
})
export class CurrencySelectComponent implements AfterViewInit {
  @ViewChild('root') root!: ElementRef;
  showOptions = false;

  options = countries.map(c => ({ countryCode: c.countryCode, text: `${c.countryNameLocal} (${c.currencyCode})` }));
  selectedOption = this.options[0];

  @Input() value!: WritableSignal<string>;
  @Output() valueChange = new EventEmitter<string>();

  constructor() {
    effect(() => {
      this.selectedOption = this.options.find(option => option.countryCode === this.value()) || this.options[0];
      this.valueChange.emit(this.selectedOption.countryCode);
    });
  }

  ngAfterViewInit() {
    this.root.nativeElement.addEventListener('click', (ev: Event) => {
      ev.stopPropagation();
    });
    this.root.nativeElement.ownerDocument.addEventListener('click', () => {
      this.showOptions = false;
    });
  }

}
