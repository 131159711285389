import { Component, Input } from '@angular/core';

@Component({
  selector: 'cs-container',
  imports: [],
  templateUrl: './container.component.html',
  styleUrl: './container.component.scss',
})
export class ContainerComponent {
  @Input() classes: string | undefined;
}
