import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroShieldCheck, heroStar, heroHeart, heroGift } from '@ng-icons/heroicons/outline';
import { heroShieldCheckSolid } from '@ng-icons/heroicons/solid';
import { CheckIconComponent } from '../../icons/check-icon/check-icon.component';
import { ButtonDirective } from '../../directives/button.directive';
import { AuthService } from '../../services/auth.service';
import { map, of } from 'rxjs';

@Component({
  selector: 'cs-pricing-plan',
  imports: [CommonModule, RouterLink, ButtonDirective, CheckIconComponent, NgIconComponent],
  providers: [provideIcons({ heroShieldCheck, heroShieldCheckSolid, heroHeart, heroStar, heroGift })],
  templateUrl: './pricing-plan.component.html',
  styleUrl: './pricing-plan.component.scss'
})
export class PricingPlanComponent {
  constructor(private authService: AuthService) {
  }

  @Input() featured = false;
  @Input() popular = false;
  @Input() topTier = false;
  @Input() free = false;
  @Input() hideButton = false;
  @Input() name!: string;
  @Input() description!: string;
  @Input() productId!: string;
  @Input() price?: string | null;
  @Input() country?: string | null;
  @Input() cryptoPrice?: string | null;
  @Input() cryptoSymbol?: string | null;
  @Input() billingCycle!: string;
  @Input() features!: string[];
  @Input() getStartedLink!: string;

  getCallToActionText() {
    if (this.isCurrentPlan()) {
      return of('Current Plan');
    }
    const text = this.authService.isProfileComplete$.pipe(map(complete => complete ? 'Subscribe' : 'Get Started'));
    text.subscribe();
    return text;
  }

  getCallToActionLink() {
    if (this.isCurrentPlan()) {
      return of('');
    }
    return this.authService.isProfileComplete$.pipe(map(complete => complete ? `/my-account/upgrade/${this.productId}` : '/get-started'));
  }

  isCurrentPlan() {
    return this.productId === 'free';
    //TODO
    // return this.productId === this.authService.userProfile()?.subscription?.productId;
  }
}
