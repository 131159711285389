import { Component } from '@angular/core';
import { ContainerComponent } from '../container/container.component';
import { CommonModule } from '@angular/common';
import { QuoteIconComponent } from '../../icons/quote-icon/quote-icon.component';

@Component({
  selector: 'cs-testimonials',
  imports: [CommonModule, ContainerComponent, QuoteIconComponent],
  templateUrl: './testimonials.component.html',
  styleUrl: './testimonials.component.scss'
})
export class TestimonialsComponent {
  testimonials = [
    [
      {
        content:
          "TaxPal is so easy to use I can't help but wonder if it's really doing the things the government expects me to do.",
        author: {
          name: 'Sheryl Berge',
          joined: '2024',
          image: '/assets/images/avatars/avatar-1-sm.jpg',
        },
      },
      {
        content:
          "I'm trying to get a hold of someone in support, I'm in a lot of trouble right now and they are saying it has something to do with my books. Please get back to me right away.",
        author: {
          name: 'Amy Hahn',
          joined: '2024',
          image: '/assets/images/avatars/avatar-2-sm.jpg',
        },
      },
    ],
    [
      {
        content:
          "The best part about TaxPal is every time I pay my employees, my bank balance doesn't go down like it used to. Looking forward to spending this extra cash when I figure out why my card is being declined.",
        author: {
          name: 'Leland Kiehn',
          joined: '2024',
          image: '/assets/images/avatars/avatar-3-sm.jpg',
        },
      },
      {
        content:
          "There are so many things I had to do with my old software that I just don't do at all with TaxPal. Suspicious but I can't say I don't love it.",
        author: {
          name: 'Erin Powlowski',
          joined: '2024',
          image: '/assets/images/avatars/avatar-1-sm.jpg',
        },
      },
    ],
    [
      {
        content:
          "I used to have to remit tax to the EU and with TaxPal I somehow don't have to do that anymore. Nervous to travel there now though.",
        author: {
          name: 'Peter Renolds',
          joined: '2024',
          image: '/assets/images/avatars/avatar-2-sm.jpg',
        },
      },
      {
        content:
          "This is the fourth email I've sent to your support team. I am literally being held in jail for tax fraud. Please answer your damn emails, this is important.",
        author: {
          name: 'Amy Hahn',
          joined: '2024',
          image: '/assets/images/avatars/avatar-3-sm.jpg',
        },
      },
    ],
  ];
}
