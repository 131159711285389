import { Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { BehaviorSubject } from 'rxjs';
import { ScrollToDirective } from '../../directives/scroll-to.directive';

@Component({
  selector: 'cs-mobile-nav',
  imports: [CommonModule, RouterLink, ScrollToDirective],
  templateUrl: './mobile-nav.component.html',
  styleUrl: './mobile-nav.component.scss'
})
export class MobileNavComponent {
  @ViewChild('mobileMenuOverlay') mobileMenuOverlay!: ElementRef<HTMLElement>;
  open$ = new BehaviorSubject<boolean>(false);
  open = false;
  isAuthenticated = this.authService.isAuthenticated$;

  constructor(private authService: AuthService) {
    this.open$.subscribe((open) => {
      this.open = open;
    });

    window.document.onclick = (event) => {
      if (this.open && !event.composedPath().includes(this.mobileMenuOverlay.nativeElement.parentNode!)) {
        this.open$.next(false);
      }
    };
  }

  toggleMenu() {
    this.open$.next(!this.open);
  }
}
