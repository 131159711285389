import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroEnvelope, heroEnvelopeOpen, heroUser, heroArrowTopRightOnSquare } from '@ng-icons/heroicons/outline';
import { heroHeartSolid } from '@ng-icons/heroicons/solid';

import { LogoComponent } from '../logo/logo.component';
import { MobileNavComponent } from '../mobile-nav/mobile-nav.component';
import { ContainerComponent } from '../container/container.component';
import { AuthService } from '../../services/auth.service';
import { NavLinkDirective } from '../../directives/nav-link.directive';
import { ButtonDirective } from '../../directives/button.directive';
import { ScrollToDirective } from '../../directives/scroll-to.directive';
import { heroStarMini } from '@ng-icons/heroicons/mini';

@Component({
  selector: 'cs-header',
  imports: [CommonModule, RouterLink, ContainerComponent, LogoComponent, NavLinkDirective, ButtonDirective, ScrollToDirective, MobileNavComponent, NgIconComponent],
  providers: [provideIcons({
    heroEnvelope,
    heroEnvelopeOpen,
    heroHeartSolid,
    heroUser,
    heroStarMini,
    heroArrowTopRightOnSquare
  })],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  hoverMessages = false;
  isAuthenticated = this.authService.isAuthenticated$;

  constructor(private authService: AuthService) {
  }

  canUpgrade() {
    // TODO: Implement this
    // if user is not a "star" member, they can still upgrade
    // return this.authService.userProfile()?.membership !== 'premium';
    return true;
  }
}
