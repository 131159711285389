import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContainerComponent } from '../container/container.component';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { HotspotsComponent } from '../hotspots/hotspots.component';

@Component({
  selector: 'cs-hero',
  imports: [CommonModule, ContainerComponent, SearchBarComponent, HotspotsComponent],
  templateUrl: './hero.component.html',
  styleUrl: './hero.component.scss'
})
export class HeroComponent {
  constructor() {}
}
