import { Component } from '@angular/core';

@Component({
  selector: 'cs-quote-icon',
  imports: [],
  templateUrl: './quote-icon.component.html',
  styleUrl: './quote-icon.component.scss'
})
export class QuoteIconComponent {

}
