import { Component, EventEmitter, Input, Output, WritableSignal } from '@angular/core';
import { ICountryPrices } from '../../../shared/prices';

@Component({
  selector: 'cs-billing-cycle-select',
  imports: [],
  templateUrl: './billing-cycle-select.component.html',
  styleUrl: './billing-cycle-select.component.scss',
})
export class BillingCycleSelectComponent {
  @Input() value!: WritableSignal<keyof ICountryPrices>;
  @Output() valueChange = new EventEmitter<keyof ICountryPrices>();

  selectCycle($event: Event, cycle: keyof ICountryPrices) {
    this.value.set(cycle);
    this.valueChange.emit(cycle);
    $event.preventDefault();
  }
}
