import { Injectable } from '@angular/core';
import { GetUserResponse, UserClient } from './api.services';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private cache: Record<string, GetUserResponse | undefined> = {};
  private promises: Record<string, Promise<GetUserResponse> | undefined> = {};

  constructor(private userClient: UserClient) {
  }

  async get(userId: string, skipCache = false): Promise<GetUserResponse> {
    if (this.promises[userId]) {
      return this.promises[userId];
    }
    if (this.cache[userId] && !skipCache) {
      return this.cache[userId];
    }
    return this.promises[userId] = new Promise<GetUserResponse>((resolve, reject) => {
      this.userClient.get(userId).pipe().subscribe({
        next: user => {
          this.cache[userId] = user;
          resolve(user);
        },
        error: error => {
          delete this.cache[userId];
          reject(error);
        },
        complete: () => {
          delete this.promises[userId];
        }
      });
    });
  }
}
