import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild, WritableSignal, effect } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroChevronDownMini } from '@ng-icons/heroicons/mini';
import cryptoCurrencies, { ICryptoCurrency } from '../../../shared/cryptoCurrencies';
import { CryptoIconComponent } from '../crypto-icon/crypto-icon.component';

@Component({
  selector: 'cs-crypto-select',
  imports: [CommonModule, NgIconComponent, CryptoIconComponent],
  providers: [provideIcons({ heroChevronDownMini })],
  templateUrl: './crypto-select.component.html',
  styleUrl: './crypto-select.component.scss'
})
export class CryptoSelectComponent implements AfterViewInit {
  @ViewChild('root') root!: ElementRef;
  showOptions = false;

  options = cryptoCurrencies;

  selectedOption = this.options[0];
  @Input() value!: WritableSignal<string>;
  @Output() valueChange = new EventEmitter<string>();
  @Output() opened = new EventEmitter<boolean>();

  constructor() {
    effect(() => {
      this.selectedOption = this.options.find((option) => option.symbol === this.value()) || this.options[0];
      this.valueChange.emit(this.selectedOption.symbol);
    });
  }

  ngAfterViewInit() {
    this.root.nativeElement.addEventListener('click', (ev: Event) => {
      ev.stopPropagation();
    });
    this.root.nativeElement.ownerDocument.addEventListener('click', () => {
      this.showOptions = false;
    });
  }

  toggleOpen() {
    this.showOptions = !this.showOptions;
    this.opened.emit(this.showOptions);
  }

  setValue(opt: ICryptoCurrency) {
    this.value.set(opt.symbol);
    this.toggleOpen();
  }

}
