import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Hotkey } from './hotkeys.model';
import { HotkeysService } from './hotkeys.service';

@Component({
  selector: 'cs-hotkeys-cheatsheet',
  imports: [CommonModule],
  templateUrl: './hotkeys-cheatsheet.component.html',
  styleUrl: './hotkeys-cheatsheet.component.scss'
})
export class HotkeysCheatsheetComponent implements OnInit, OnDestroy {
  helpVisible$ = new BehaviorSubject(false);
  @Input() title = 'Keyboard Shortcuts:';
  subscription: Subscription | undefined;

  hotkeys: Hotkey[] = [];

  constructor(private hotkeysService: HotkeysService) {}

  public ngOnInit(): void {
    this.subscription = this.hotkeysService.cheatSheetToggle.subscribe((isOpen) => {
      if (isOpen !== false) {
        this.hotkeys = this.hotkeysService.hotkeys.filter((hotkey) => hotkey.description);
      }

      if (isOpen === false) {
        this.helpVisible$.next(false);
      } else {
        this.toggleCheatSheet();
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public toggleCheatSheet(): void {
    this.helpVisible$.next(!this.helpVisible$.value);
  }
}
